// Color Variables:
$main-color-dark: #073280;
$main-color-lighter: #a3cef1;
$accent-dark: #ff9f1c;
$accent-lighter: #ffbf69;
$light-blue: #e7ecef;
$button-hover-teal: #558fa4;
$light-brown: bisque;

$dark-purple: #612C69;
$middle-purple: #85367B;
$light-purple: #943C84;
$dark-green: #275D3A;
$middle-green: #02833F;
$light-green: #98C11D;
$black: black;
$white: white;
$light-grey: #F5F4F5;



video{
  width: 100%;
}
html {
  font-size: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

img {
  width: 100%;
  height: 22rem;
  object-fit: cover;
}

.logo{
  width: 2rem;
  height: 100%;
}

@media (min-width: 33em) {
  img {
    height: 40em;
  }
}

a,
li,
p,
h1,
h2, h3, h4{
  text-decoration: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

// Wrapper container
.wrapper {
  padding: 0 2rem;
}

.info-wrapper {
  padding: 0 2rem;
}

// Flexbox
.flex {
  display: flex;

  &-jc-sb {
    justify-content: space-between;
  }

  &-ai-c {
    align-items: center;
  }

  &-jc-sa {
    justify-content: space-around;
  }

  &-fd-c {
    flex-direction: column;
  }

  &-gap {
    gap: 2rem;
  }

  &-gap-small{
    gap: 0.8rem;
  }

  &-wrap {
    flex-wrap: wrap;
    justify-content: center;
  }

  &-wrap-right{
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}

.object-fit {
  height: 40rem;
  object-fit: cover;
}

// Input fields
input[type="text"], input[type="email"] {
  height: 3rem;
  width: 100%;
  outline: none;
  border: none;
  font-size: 1rem;
  background-color: $light-blue;
  font-family: "Poppins", sans-serif;
  margin: 0.5rem 0;
  transition: all 0.3s ease-out;

  &:focus {
    border-bottom: 2px solid $main-color-dark;
    -webkit-box-shadow: 0px 0px 24px -4px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 24px -4px rgba(0, 0, 0, 0.12);
  }
}

textarea {
  background-color: $light-blue;
  width: 100%;
  font-size: 1rem;
  border: none;
  font-family: "Poppins", sans-serif;
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none;

  &:focus {
    border-bottom: 2px solid $main-color-dark;
    -webkit-box-shadow: 0px 0px 24px -4px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 24px -4px rgba(0, 0, 0, 0.12);
  }
}

@media screen and (min-width: 50em) {
  .wrapper {
    padding: 0 5rem;
  }
}

@media screen and (min-width: 100em) {
  .wrapper {
    padding: 0 20rem;
  }
}
  