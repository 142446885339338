.card {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  text-align: center;
  width: 20rem;
  gap: 1rem;
  padding: 2rem;
  border-radius: 2rem;
  -webkit-box-shadow: 0px 3px 36px 0px rgba(0, 0, 0, 0.052);
  box-shadow: 0px 3px 36px 0px rgba(0, 0, 0, 0.042);
  transition: transform 0.3s ease-in-out;
  position: relative;

  h3{
    color: $dark-purple;
  }
  p{
    color: $black;
  }
  &:hover {
    transform: translateY(-0.5rem);
  }
  .icon{
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    background-color: $light-blue;
    display: flex;
    position: relative;
    img{
      height: auto;
      width: 4rem;
      justify-content: center;
      align-items: center;
      margin: auto;
    }
  }
}

.arrow {
  transition: all 0.5s ease-in-out;
  font-size: 1.2rem;
  color: $middle-purple;
}

.card-button{
  font-weight: bold;
  color: $middle-purple;

  &:hover~.arrow{

    transform: translateX(0.5rem);
    color: $light-green;
    
  }
}



