@import "src/scss/float-image";
@import "src/scss/globals";
@import "src/scss/dialogbox";
@import "src/scss/landingpages";
@import "src/scss/navigation";
@import "src/scss/cta-section";
@import "src/scss/footer";
@import "src/scss/buttons";

.image-container{
  .hero-img{
    background-image: url("../images/about/7.jpeg");
  }
}

.square-container {
  display: none;
}

.dotted-container {
  display: none;
}

// MISSION STATEMENT SECTION

.business-statement-container{
display: flex;
height: 70vh;
justify-content: center;
align-items: center;
padding: 2rem;
background-color: $light-grey;
}

.vision-container {
  h2 {
    font-size: 1.5rem;
    color: $dark-purple;
  }

  p {
    font-size: 1rem;
  }
}

.mission-container {
  h2 {
    font-size: 1.5rem;
    color: $light-green;
  }

  p {
    font-size: 1rem;
  }
}

// PROMO SECTION

.text-container-left {
  margin-top: 1rem;
  margin-bottom: 3rem;
  h3 {
    color: $dark-purple;
    margin-top: 1rem 0;
  }

  p {
    margin-bottom: 3rem;
  }

  a {
    background-color: $dark-purple;
    margin-bottom: 3rem;
  }
}

.text-container-right {
  margin-top: 1rem;
  margin-bottom: 3rem;
  h3 {
    color: $dark-purple;
    margin-top: 1rem 0;
  }

  p {
    margin-bottom: 3rem;
  }

  a {
    background-color: $dark-purple;
    margin-bottom: 3rem;
  }
}

// CONTACT US SECTION

.about-contact-grid{
  background-color: $light-green;
}
.contact-text-container-right-aboutpage {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: right;
  align-items: flex-end;

  h2{
    color: $dark-purple;
  }
  p{
    color: $light-grey;
  }
}

.about-promo-contact-buttons{
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 1rem;
  margin-bottom: 2rem;
}

.button-contact-phone {
  background-color: transparent;
  font-size: 1rem;
  border-radius: 0.5rem;
  padding: 0.65rem 2rem;
  border: 3px $dark-purple solid;
  color: $light-grey;
  border-color: $middle-green;
  transition: all 0.5s ease-in-out;

  &:hover {
    background-color: rgb(36, 182, 114);
    color: white;
    border-color: rgb(36, 182, 114);
  }
}


//MEDIA QUERY 50EM

@media (min-width: 50em) {

  //   ILLUSTRATIONS
  .square-container {
    display: block;
    position: absolute;
    top: 35.7rem;
    right: 47.5%;
    width: 10rem;
    z-index: 99;

    img {
      width: 100%;
      height: auto;
    }
  }

  .dotted-container {
    display: block;
    position: absolute;
    top: 6rem;
    right: 52%;
    width: 5rem;

    img {
      width: 100%;
      height: auto;
    }
  }

  // VISION STATEMENT SECTION

  .business-statement-container {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .vision-section-text-container {
    width: 50%;
  }

  .vision-container {
    z-index: 99;
    h2 {
      font: 2.5rem;
    }

    p {
      font-size: clamp(0.8rem, 1vw, 1.5rem);
    }
  }

  .mission-container {
    text-align: right;
    margin-top: 3rem;
    p {
      font-size: clamp(0.8rem, 1vw, 1.5rem);
    }
  }
  
  // PROMO SECTION
  .grid {
    display: grid;
    grid-template-areas:
      "photo text"
      "photo text"
      "photo text";
    grid-template-columns: repeat(2, 1fr);
    align-items: center;

    &-reverse {
      display: grid;
      grid-template-areas: "text photo";
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
    }
  }

  .promo-card-container-reverse {
    img {
      grid-area: photo;
    }
  }

  .text-contianer-right {
    img {
      grid-area: text;
    }
  }

    .about-contact-grid {
      color: white;
      display: grid;
      grid-template-areas: "photo text";
      grid-template-columns: 1fr 1fr;
      align-items: center;
      
    }
}

@media (min-width: 75em) {
  .business-statement-container {
    position: relative;
  }

  .text-container {
    padding: 0;
  }

  .vision-container{
    p{
      font-size: 1rem;
    }
  }

  .mission-container{
    p{
      font-size: 1rem;
    }
  }

  .promo-card-container{
    img{
      width: 100%;
    }
  }
}
