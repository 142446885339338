.footer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.creditation-container{
  display: flex;
  .ndis-logo-container{
    display: flex;
    gap: 1.5rem;
    align-items: center;

    img{
      width: 4rem;
      height: auto;
      transition: all 0.3s ease-in-out;
      
      filter: grayscale(100%);
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      &:nth-child(2){
        width: 6rem;
      }

      &:hover{
        filter: grayscale(0%);
        -webkit-filter: grayscale(0%);
        -moz-filter: grayscale(0%);
        transform: scale(1.05);
      }
    }
  }
}

.footer-links {
  margin: 4rem 0;
  justify-content: center;
  gap: 1rem;

  a {
    color: $main-color-dark;
  }
}

.footer-copyright {
  font-size: 0.8rem;
  text-align: center;
  margin: 2rem 0;
}