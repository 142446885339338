.landing-page {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 50em) {
        display: grid;
        grid-template-areas: "left right";
        align-items: center;
        grid-template-columns: 1fr 1fr;
        justify-content: space-between;
    }
}

.left-content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1.5rem;

    @media screen and (min-width: 50em) {
        align-items: flex-start;
    }

    .landing-text-container{
        color: white;
        text-align: right;

        @media screen and (min-width: 50em) {
            text-align: left;
            padding-right: 4rem;
        }

        
        h2 {
            font-size: clamp(2rem, 5vw, 10rem);
            text-transform: lowercase;

            @media screen and (min-width:50em) {
                color: $dark-purple;
            }
        }

        p {
            font-size: clamp(0.8rem, 2vw, 0.9rem);
            @media screen and (min-width:50em) {
                    color: $black;
                }
        }
    }
}


.image-container{
    .hero-img{
        background-size: cover;
        background-color: rgba($color: #4e4e4e, $alpha: 0.4);
        background-blend-mode: multiply;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        z-index: -9999;
        top: 0;
        right: 0;
        z-index: -999;
        width: 100%;
        height: 100vh;

        @media screen and (min-width: 50em) {
            width: 50%;
        }
    }
}