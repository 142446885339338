@import "src/scss/globals";

.nav-drop-shadow{
  -webkit-box-shadow: 0px 3px 36px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 3px 36px 0px rgba(0, 0, 0, 0.2);
}

.header {
  position: fixed;
  top: 2rem;
  z-index: 999999;
  width: 80%;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: rgba(245, 244, 245, 0.52);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.3px);
  -webkit-backdrop-filter: blur(8.3px);
  border: 1px solid rgba(245, 244, 245, 0.3);
  padding: 0.8rem 5rem;

  transition: all 1.5s ease-in-out;
  nav {
    background-color: transparent;
    transition: all 0.5s ease-in-out;
    .link-container{      
      a {
        color: black;
        padding: 1rem;
  
        &:active{
          color: $light-purple;
        }
  
        &:hover {
          color: $light-purple;
        }
      }
    }
  }
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover{
    cursor: pointer;
  }
}

#burger {
  color: $dark-purple;
  font-size: 30px;
  cursor: pointer;
  display: none;
}

@media (max-width: 50em) {

  .header{
    padding: 1rem 2rem;
  }

  #burger{
    display: block;
    z-index: 9999999;
  }

  nav {
    opacity: 0;
    height: fit-content;
    z-index: 999999;
    transition: all 0.2s ease-in-out;
    display: none;

    .link-container{
      flex-direction: column;
      margin-top: 8rem;
      align-items: flex-end;
      padding: 0 1.5rem;
      height: 100%;
      font-size: 1.5rem;
      display:none;
    }
    a:hover {
      color: $light-purple;
    }
  }
  nav.active {
    position: absolute;
    top: 0;
    right: 0;
    width: 80vw;
    display: flex;
    height: 100vh;
    flex-direction: column;
    text-align: right;
    opacity: 1;
    .link-container{
      display: flex;
    }
  }
}

.header-active{
  height: 100vh;
  width: 100vw;
  border-radius: 0;
  top: 0;

  align-items: flex-start;
}
