.button {
  background-color: $middle-purple;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  padding: 0.8rem 2rem;
  border: none;
  border-radius: 0.5rem;
  transition: background-color ease-in-out 0.5s;

  &:hover {
    cursor: pointer;
    background-color: $light-purple;
  }
}