.dialog-close-btn-container {
    position: absolute;
    top: 0;
    right: -1rem;
    z-index: 9999999;

    i {
        color: darkgray;
        border: none;
        border-radius: 0.5rem;
        padding: 0.5rem 1.5rem;
        font-size: 3rem;
        font-weight: bold;
        transition: all 0.5s ease-in;

        &:hover {
            color: rgb(255, 116, 116);
            cursor: pointer;
        }
    }
}

dialog {
    border: none;
    background-color: transparent;
    overflow: hidden;

    &::backdrop {
        background: rgba(245, 244, 245, 0.75);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(11.4px);
        -webkit-backdrop-filter: blur(11.4px);
        overflow: hidden;
    }
}

.dialog {
    scrollbar-width: thin;
    scroll-behavior: smooth;

    @media screen and (min-width: 70em) {
        width: 50rem;
    }

    form {

        h3 {
            font-family: "DM Serif Display", serif;
            font-size: 2rem;
            color: $dark-purple;
        }

        h4 {
            font-size: 1.2rem;
            color: $light-green;
            margin-top: 0.8rem;
            margin-bottom: 2.5rem;
        }

        input,
        textarea {
            background-color: transparent;
            border-bottom: 1px solid $light-grey;
            border-radius: 0.5rem;
            margin-bottom: 1rem;

            &::placeholder {
                color: $dark-purple;
            }

            &:focus {
                outline: none;
                border-bottom: 1px solid $light-green;
            }
        }

        textarea {
            height: 10rem;
        }

        button {
            background-color: $middle-green;

            &:hover {
                background-color: $light-green;

            }
        }
    }
}

// FLOATING BUTTON
.floating-btn-container {
    position: fixed;
    bottom: 3rem;
    right: 3rem;
    z-index: 999999;

    .floating-btn {
        background-color: $light-green;
        color: $light-grey;
        border: none;
        border-radius: 0.5rem;
        padding: 0.5rem 1.5rem;
        font-size: 1.2rem;
        font-weight: bold;
        transition: all 0.5s ease-in-out;

        &:hover {
            cursor: pointer;
            background-color: $light-purple;
        }

    }
}