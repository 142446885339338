@import "src/scss/globals";
@import "src/scss/navigation";
@import "src/scss/dialogbox";
@import "src/scss/buttons";
@import "src/scss/card";
@import "src/scss/footer";
@import "src/scss/landingpages";
@import "src/scss/faq";
@import "src/scss/cta-section";

// MAIN SECTION
.text-wrapper {
  padding-left: 5rem;
}

.hero-text-left {
  position: relative;
  text-align: right;

  h1 {
    color: $light-grey;
    font-size: clamp(2rem, 5vw, 10rem);
    line-height: 2rem;
  }

  p {
    color: $light-grey;
    font-size: clamp(0.8rem, 2vw, 0.9rem);
    margin-top: 2rem;
    margin-bottom: 3rem;
  }
  a{
    background-color: $light-purple;
  }
}

.black-background {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 9/16;
  z-index: -9999;

  @media screen and (min-width: 50em) {
    aspect-ratio: 16/9;
  }
}

// SERVICES SECTION

.services-section {
  background-color: $light-grey;
  text-align: center;
  padding: 2rem 0;
}

.services-header-text-container {
  h2 {
    text-transform: lowercase;
    color: $dark-purple;
    font-size: clamp(2rem, 5vw, 3.5rem);
  }
  p {
    color: $black;
    margin-bottom: 4rem;
    font-size: 1.2rem;
  }
}

.card-cta-container{
  border: 0.2rem solid $light-purple;
  border-radius: 0.8rem;
  padding: 0.5rem 1.5rem;
}

.service-cta{
  margin-top: 3rem;
  .more-services-button {
      background-color: $light-purple;
      color: $light-grey;
      border: none;
      border-radius: 0.5rem;
      padding: 0.5rem 1.5rem;
      font-size: 1.2rem;
      font-weight: bold;
      transition: all 0.5s ease-in-out;

      &:hover{
        background-color: $light-green;
      }
    }
}

// PROMO SECTION
.promo-text-container-right {
  margin-top: 1rem;
  margin-bottom: 3rem;

  h2 {
    color: $light-green;
    margin: 1rem 0;
  }

  p {
    color: $black;
    margin-bottom: 3rem;
  }

  .button-about {
    color: $light-grey;
    background-color: $light-green;

    &:hover{
      background: $middle-green;
    }
  }
}

.promo-text-container-left {
  margin-top: 1rem;
  margin-bottom: 3rem;
  h2 {
    color: $accent-dark;
    margin: 1rem 0;
  }

  p {
    margin-bottom: 3rem;
  }

  a {
    background-color: $accent-dark;
    margin-bottom: 3rem;
  }
}

// CONTACT US SECTION
.contact-text-container-right {
  text-align: right;
  margin-bottom: 2rem;

  h2 {
    color: $light-grey;
    margin: 1rem 0;
  }

  p {
    color: $light-grey;
    margin-bottom: 3rem;
  }

  button {
    margin-bottom: 3rem;
  }
}

.promo-container-contact{
  background-color: $dark-purple;
  .contact-text-container-right{
    padding-bottom: 2rem;
    margin: 0;
  }
}

.promo-contact-buttons{
  gap: 1rem;
  justify-content: flex-end;
}

.button-contact-phone {
  background-color: transparent;
  font-size: 1rem;
  border-radius: 0.5rem;
  padding: 0.65rem 2rem;
  border: 3px $main-color-dark solid;
  transition: all 0.5s ease-in-out;
  color: $light-grey;
  &:hover {
    background-color: rgb(36, 182, 114);
    border-color: rgb(36, 182, 114);
  }
}

// MEDIA QUERY

@media (min-width: 50em) {
  .hero-text-left {
    width: 55vw;
    text-align: left;
    h1 {
      line-height: 3.3rem;
    }
  }

  //PROMO SECTION

  .promo-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }

  // CONTACT IN THE PROMO SECTION.

  .promo-container-second {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    img {
      grid-column-start: 2;
      grid-row-end: 2;
    }
  }

  .promo-container-contact{
    color: white;
    display: grid;
    grid-template-areas: "photo text";
    grid-template-columns: 1fr 1fr;
    align-items: center;

    h2{
      color: white;
    }

    .button{
      background-color: $accent-dark;

      &:hover{
        background-color: orange;
      }
    }
    .button-contact-phone{
      border-color: #24b672;
      color: $light-grey;
    }
  }
}

@media (min-width: 70em) {
  .landing-page{
    gap: 5rem;
  }
  .hero-text-left {
    h1 {
      line-height: 4.5rem;
    }
  }
}

@media (min-width: 100em) {
  .hero-text-left {
    h1 {
      line-height: 7rem;
    }
  }
}