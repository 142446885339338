.flex-box-wrapper{
    background-image: url('../images/family.jpg');
    background-size: cover;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.3);
    background-blend-mode: overlay;
    margin-top: 2rem;
    border-radius: 3rem;
    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;

    @media screen and (min-width: 60em){
        margin-top: 5rem;
        padding: 2rem;
        flex-direction: row;
    }

    @media screen and (min-width: 100em){
        
    }
    .text-container{
        color: $white;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        flex-grow: 1;
        @media screen and (min-width: 50em) {
            gap: 1rem;
            align-items: flex-start;
            text-align: left;
        }
        h2{
            font-size: clamp(2rem, 5vw, 3.5rem);
            line-height: clamp(2.8rem, 5vw, 4.2rem);
            @media screen and (min-width: 50em) {
                text-align: left;
            }
        }
        p{
            text-align: center;
            font-size: clamp(0.8rem, 5vw, 1rem);

            @media screen and (min-width: 50em) {
                text-align: left;
            }
        }
    }

    .form-container{
        background: rgba(245, 244, 245, 0.75);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(11.4px);
        -webkit-backdrop-filter: blur(11.4px);
        padding: 1.2rem;
        border-radius: 0.8rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media screen and (min-width: 60em) {
            padding: 2rem;
            width: 85%;
        }
        
        h2{
            color: $dark-purple;
            font-size: clamp(2rem, 5vw, 3.5rem);
            margin-bottom: 2rem;
        }
        p{
            color: $black;
            margin-bottom: 2rem;
            font-size: 1.2rem;
        }
        form{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 0.8rem;

            @media screen and (min-width: 60em){
                gap: 1.8rem;
            }
            input, textarea{
                width: 100%;
                padding: 1rem;
                border: none;
                border-radius: 5px;
                margin: 0;
            }
            
            #servicesDropDown {
                font-size: 1rem;
                font-family: "Poppins", sans-serif;
                color: gray;
                background-color: $light-blue;
                width: 100%;
                padding: 1rem;
                border: none;
                border-radius: 5px;
                margin: 0;

                &:focus {
                    outline: none;
                }
            }

            button{
                padding: 1rem 2rem;
                border: none;
                border-radius: 5px;
                background-color: $dark-purple;
                color: $white;
                font-size: 1.2rem;
                cursor: pointer;
            }
        }
    }
}