@import "src/scss/globals";

.faqs-section{
    background-color: $light-brown;
}

.faq-text-container{
    padding-top: 3rem;
    h2{
        color: $dark-purple;
        font-size: 2.2rem;
    }
}

.faq-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 0;
}

.question-container{
    width: 100%;
}
.accordian-btn{
    font-weight: bold;
    font-size: 1rem;
    margin: 0.2rem 0;
    background-color: $light-grey;
    color: $dark-purple;
    cursor: pointer;
    padding: 1.5rem;
    width: 100%;
    text-align: left;
    border-radius: 0.2rem;
    border: none;
    outline: none;
    transition: 0.5s;
    -webkit-box-shadow: -4px 0px 14px -5px rgba(0, 0, 0, 0.1);
    box-shadow: -4px 0px 14px -5px rgba(0, 0, 0, 0.1);

    &::after{

        content: '\02795';
        font-size: 13px;
        float: right;
        margin-left: 5px;
    }
}

.active, 
.accordion-btn:hover {
    background-color: white;
    transition: ease-in 0.5s;
}

.active:after {
    content: "\2796";
    /* Unicode character for "minus" sign (-) */
}

.answer-container {
    width: 100%;
    padding: 0 1.5rem;
    color: $middle-purple;
    background-color: transparent;
    display: none;
    overflow: hidden;
}
