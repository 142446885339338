.free-floating-image-container {
  display: none;
}

@media (min-width: 75em) {
  .free-floating-image-container {
    display: block;
    img {
      width: 15rem;
      height: 20rem;
      position: absolute;
      -webkit-box-shadow: 0px 3px 38px 5px rgba(0, 0, 0, 0.07);
      box-shadow: 0px 3px 38px 5px rgba(0, 0, 0, 0.2);
    }

    :nth-child(1) {
      bottom: -25rem;
      right: -5rem;
      z-index: 999;
      width: 20rem;
      height: 25rem;
    }

    :nth-child(2) {
      top: 15rem;
      right: 5rem;
      z-index: 99;
    }

    :nth-child(3) {
      top: -5rem;
      right: 10rem;
    }

    :nth-child(4) {
      top: 10rem;
      left: 2rem;
    }

    :nth-child(5) {
      top: -5rem;
      left: 5rem;
      z-index: 99;
    }

    :nth-child(6) {
      top: 8rem;
      left: -5rem;
    }
    :nth-child(7) {
      top: 35rem;
      left: -7rem;
      width: 20rem;
      height: 25rem;
    }

    :nth-last-child(1) {
      top: 10rem;
      right: -4rem;
      z-index: 999;
    }
  }
}
